import React from "react";
import "../App.scss";
import Picture from "../gfx/ueberuns.jpg";
import Card from "react-bootstrap/Card";

export default function Home() {
  return (
    <div id="home" className="wrapper">
      <Card className="center" style={{ width: "95%", maxWidth: "600px" }}>
        <Card.Img variant="top" src={Picture} />
        <Card.Body className="justify">
          <Card.Title className="topic">Über uns</Card.Title>
          <Card.Text>
            Wir sind der Förderverein der Kita Lachengraben in
            Dreieich-Götzenhain. Der Verein ist ein Zusammenschluss von Eltern
            und Freunden der Kita und fördert selbstlos und als gemeinnütziger
            nicht-eingetragener Verein die Ziele und Arbeit des Kindergartens.
            Unsere Verein wurde am 17.12.2021 gegründet.
          </Card.Text>
          <Card.Text>
            Der Förderverein finanziert auf Antrag Angebote, Projekte und
            Sachmittel für den Kindergarten Lachengraben, welche aus den
            regulären Betriebsmitteln nicht finanziert werden können. Die dafür
            erforderlichen Gelder generiert der Förderverein durch
            Spendeneinnahmen.
          </Card.Text>
          <Card.Text>
            Die Kita-Leitung stellt Anträge zur Finanzierung von Anschaffungen
            oder Projekten. Der Verein entscheidet über diese Anträge. Bei
            positivem Votum finanziert er diese Anschaffung oder das Projekt.
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}
