import React from "react";
import Navbar from "react-bootstrap/Navbar";
import { Container, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Navigation.scss";

export default function Navigation() {
  return (
    <div className="navigationContainer">
      <Navbar
        collapseOnSelect
        className="nav-style"
        expand="lg"
        fixed="top"
      >
        <Container>
          <Navbar.Brand>
            <span className="brandName">Förderverein Kita Lachengraben</span>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link
                eventKey={1}
                as={Link}
                to="/home"
              >
                Startseite
              </Nav.Link>

              <Nav.Link
                eventKey={3}
                as={Link}
                to="/spende"
              >
                Spenden
              </Nav.Link>
              <Nav.Link
                eventKey={4}
                as={Link}
                to="/projekte"
              >
                Projekte
              </Nav.Link>
              <Nav.Link
                eventKey={5}
                as={Link}
                to="/kontakt"
              >
                Kontakt
              </Nav.Link>
              <Nav.Link
                eventKey={6}
                as={Link}
                to="/impressum"
              >
                Impressum
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
