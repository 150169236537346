import React from "react";
import Card from "react-bootstrap/Card";
import Picture from "../gfx/kontakt.jpg";
import "../App.scss";

export default function Kontakt() {
  return (
    <div id="kontakt" className="wrapper">
      <Card
        className="text-center center"
        style={{ width: "95%", maxWidth: "600px" }}
      >
        <Card.Img variant="top" src={Picture} />
        <Card.Body>
          <Card.Title className="topic">Email</Card.Title>
          <Card.Text>
            <a href="mailto:info@fv-lachengraben.de" className="link">
              info@fv-lachengraben.de
            </a>
          </Card.Text>
          <Card.Title className="topic">Anschrift</Card.Title>
          <Card.Text>
            Förderverein Kita Lachengraben<br></br>
            Goldgrubenstraße 17<br></br>
            D-63303 Dreieich
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}

//TODO: Kontaktformular überdenken und implementieren
