import React from "react";
import Card from "react-bootstrap/Card";
import Picture from "../gfx/impressum.jpg";
import "./Impressum.scss";

export default function Impressum() {
  return (
    <div className="impressumContainer wrapper">
      <Card style={{ width: "95%", maxWidth: "600px" }}>
        <Card.Img variant="top" src={Picture} />
        <Card.Body>
          <Card.Title className="topic">Vereinsvorstand</Card.Title>
          <Card.Text>
            <h2>Nariman Mönnig (Vorstand)</h2>
            <h2>Philipp Schenck (stellvertretender Vorstand)</h2>
          </Card.Text>
          <Card.Title className="topic">Anschrift</Card.Title>
          <Card.Text>
            <h2>Förderverein Kita Lachengraben</h2>
            <h2>Goldgrubenstraße 17</h2>
            <h2>D-63303 Dreieich</h2>
          </Card.Text>
          <Card.Title className="topic">Kontakt</Card.Title>
          <Card.Text>
            <h2>info@fv-lachengraben.de</h2>
          </Card.Text>
          <Card.Title className="topic">Steuernummer</Card.Title>
          <Card.Text>
            <h2>028 250 56478 Finanzamt Langen/Hessen</h2>
          </Card.Text>
          <Card.Title className="topic">Haftung für Inhalte</Card.Title>
          <Card.Text className="justify">
            <h3>
              Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt.
              Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte
              können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter
              sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten
              nach den allgemeinen Gesetzen verantwortlich. Nach § 8 bis 10 TMG
              sind wir als Diensteanbieter jedoch nicht verpflichtet,
              übermittelte oder gespeicherte fremde Informationen zu überwachen
              oder nach Umständen zu forschen, die auf eine rechtswidrige
              Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung
              der Nutzung von Informationen nach den allgemeinen Gesetzen
              bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch
              erst ab dem Zeitpunkt der Kenntnis einer konkreten
              Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
              Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
              Streitbeilegungsplattform der EU Die Europäische Kommission stellt
              eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie{" "}
              <a href="http://ec.europa.eu/consumers/odr/" className="link">
                hier
              </a>{" "}
              finden.
            </h3>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}
