import "./Projekt.scss";
import { Card, ProgressBar } from "react-bootstrap";

import img1 from "../gfx/projects/sandspielzeug.jpg";
import img2 from "../gfx/projects/rutschautos.jpg";
import img3 from "../gfx/projects/IMG_2831.jpeg";
import img4 from "../gfx/projects/IMG_2839.jpeg";
import img5 from "../gfx/projects/farbschleuder.jpeg";
import img6 from "../gfx/projects/sofa.jpeg";
import img7 from "../gfx/projects/ruheraum.jpg";

//TODO: Anstehende Projekte und Vorhaben darstellen

export default function Projekt() {
  function projectRuheraum() {
    const now = 1400;
    const final = 1400;

    const quotient = ((now / final) * 100).toFixed(0);
    //
    function getVariant() {
      if (quotient <= 33) {
        return "danger";
      } else if (quotient > 33 && quotient <= 66) {
        return "warning";
      } else {
        return "success";
      }
    }

    return <ProgressBar variant={getVariant()} now={quotient} label={`${quotient}%`} />;
  }

  return (
    <div className="projektContainer">
      <h1>Aktuelles Projekt</h1>
      <div className="completedProjects">
        <Card>
          <Card.Img variant="top" src={img7} />
          <Card.Body>
            {projectRuheraum()}
            <Card.Title>Ruheraum ~ 1400 €</Card.Title>
            <Card.Text>
              Liebe Eltern, <br />
              <br />
              kennt ihr eigentlich den Ruheraum in unserer Kita? Die meisten von uns haben da wahrscheinlich viel Zeit
              in der Eingewöhnung verbracht. Und? Hat es euch da gefallen? Wir sind der Meinung, dass da noch viel
              gemacht werden kann!
              <br />
              <br /> Daher möchten wir heute gern einen Spendenaufruf starten: <br />
              <br />
              Verschönerung des Ruheraums der Kita am Lachengraben! <br />
              <br />
              Was soll gemacht werden: Den Kindern soll ein Raum geschaffen werden, in dem sich sich zurückziehen
              können, wenn sie einfach Ruhe brauchen oder schlafen möchten. Die Kinder sollen dort aber auch durch
              gezielte Aktivitäten dabei unterstützt werden zur Ruhe zu kommen. <br />
              <br />
              Daher hat der Kindergarten ein kleines Paket benötigen Materialien geschnürt. Dies enthält, Decken, Kissen
              und Teppiche zum Kuscheln, einen Baldachin, der für die passende Stimmung sorgen soll, aber auch
              Sinnesbälle und ein Graphomotorik-Set. <br />
              <br />
              Was kostet das? Um den Kindern einen stimmigen Raum bieten zu können, benötigen wir insgesamt 1400 Euro!
              <br />
              <br />
              Sobald wir genügend Geld gesammelt haben, kann die Kita das Material bestellen und der Raum ausgestattet
              werden! <br />
              <br />
              Also, helft mit und spendet jetzt!!
              <br />
              <br /> <a href="/spende">Hier findet Ihr unsere Bankdaten!</a> <br />
              <br />
              Euer Förderverein und der Elternbeirat! <br />
              <br />
              P.S. Wer den Raum nicht kennt, ist herzlich eingeladen mal herein zu schauen! <br />
              <br />
              P.P.S. Natürlich darf der Spendenaufruf auch gern an Großeltern, Tante, Onkel oder Freunde weitergeleitet
              werden!
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
      <h1>Abgeschlossene Projekte</h1>
      <div className="completedProjects">
        <Card>
          <Card.Img variant="top" src={img1} />
          <Card.Body>
            <Card.Title>01/2022</Card.Title>
            <Card.Text>Mehr Abwechslung durch buntes Sandspielzeug.</Card.Text>
          </Card.Body>
        </Card>

        <Card>
          <Card.Img variant="top" src={img2} />
          <Card.Body>
            <Card.Title>02/2022</Card.Title>
            <Card.Text>Maximaler Gartenspaß durch viele neue Rutschautos.</Card.Text>
          </Card.Body>
        </Card>

        <Card>
          <Card.Img variant="top" src={img3} />
          <Card.Body>
            <Card.Title>03/2022</Card.Title>
            <Card.Text>Kreativität fördern druch Blumentafeln im Außenbereich.</Card.Text>
          </Card.Body>
        </Card>

        <Card>
          <Card.Img variant="top" src={img4} />
          <Card.Body>
            <Card.Title>04/2022</Card.Title>
            <Card.Text>Mehr Abwechslung im Atelier durch eine neue Staffelei.</Card.Text>
          </Card.Body>
        </Card>

        <Card>
          <Card.Img variant="top" src={img5} />
          <Card.Body>
            <Card.Title>05/2022</Card.Title>
            <Card.Text>Bunte Vielfalt durch eine Farbschleuder.</Card.Text>
          </Card.Body>
        </Card>

        <Card>
          <Card.Img variant="top" src={img6} />
          <Card.Body>
            <Card.Title>08/2022</Card.Title>
            <Card.Text>Die neue Coach im großen Flurbereich.</Card.Text>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}
