import React from "react";
import { Card, Button } from "react-bootstrap";
import Picture from "../gfx/amazon.png";
import "../App.scss";

export default function Amazon() {
  return (
    <div id="amazon" className="wrapper">
      <Card
        className="text-center center"
        style={{ width: "95%", maxWidth: "600px" }}
      >
        <Card.Img variant="top" src={Picture} />
        <Card.Body>
          <Card.Title>Einkaufen und Gutes tun</Card.Title>
          <Card.Text>
            Wenn Sie bei smile.amazon.de einkaufen, unterstützt Amazon eine
            gemeinnützige Organisation Ihrer Wahl, ohne jegliche Extrakosten für
            Sie. Wir freuen uns, wenn Sie uns auf diesem Weg unterstützen
            können.
          </Card.Text>
          <a
            href="https://smile.amazon.de/ch/28-250-56478"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <Button id="nachweis" style={{ justifyContent: "center" }}>
              smile.amazon.de besuchen
            </Button>
          </a>
        </Card.Body>
      </Card>
    </div>
  );
}

//TODO: Amazon Prime genauer ansehen wie das funktioniert. Vielleicht nicht sofort implementieren
