import React from "react";
import "../App.scss";
import Card from "react-bootstrap/Card";
import Picture from "../gfx/spende.jpg";
import { Button } from "react-bootstrap";
import Bescheinigung from "../Vereinfachte Zuwendungsbescheinigung.pdf";

export default function Spende() {
  return (
    <div id="spende" className="wrapper">
      <Card className="center" style={{ width: "95%", maxWidth: "600px" }}>
        <Card.Img variant="top" src={Picture} />
        <Card.Body className="justify">
          <Card.Title className="topic">Spenden</Card.Title>
          <Card.Text>
            Spenden sind für uns eine tolle Unterstützung, Projekte für die
            Kinder der Kita Lachengraben zu realisieren. Wir sind als
            gemeinnütziger Verein berechtigt, Spendenquittungen auszustellen,
            welche Sie bei Ihrer Steuererklärung absetzen können.
          </Card.Text>
          <Card.Text>
            Für unsere Arbeit zählt jeder Euro, weshalb wir kein Mindestbetrag
            für eine Spende ansetzen möchten. Ihre Spende können Sie ganz
            einfach auf unser Vereinskonto überweisen. Bei Spenden bis zu 300€
            je Zahlung ist keine formale Zuwendungsbescheinigung erforderlich.
            Hierbei reicht als Nachweis für das Finanzamt ein Kontoauszug samt
            Beleg des Fördervereins aus.
          </Card.Text>
          <Button id="nachweis" href={Bescheinigung} download>
            Download vereinfachter Zuwendungsnachweis
          </Button>
          <Card.Text>
            Für Ihre großzügigen Spenden über 300€ stellen wir Ihnen gerne eine
            persönliche Spendenquittung aus. Hierfür bitten wir Sie uns im
            Verwendungszweck folgende Informationen zukommen zu lassen:
          </Card.Text>
          <ol>
            <li>Vorname + Name</li>
            <li>Adresse, PLZ + Ort</li>
            <li>Email-Adresse</li>
          </ol>
          <Card.Text>
            Anschließend stellen wir Ihnen eine Spendenquittung aus, welche wir
            Ihnen gerne elektronisch an die angegeben Email-Adresse zusenden.
          </Card.Text>
          <Card>
            <Card.Header>Unser Vereinskonto für Ihre Spende</Card.Header>
            <Card.Body>
              <Card.Title className="topic">IBAN</Card.Title>
              <Card.Text>DE46 5059 2200 0005 6244 10</Card.Text>
              <Card.Title className="topic">BIC</Card.Title>
              <Card.Text>GENODE51DRE</Card.Text>
              <Card.Title className="topic">Insitut</Card.Title>
              <Card.Text>Volksbank Dreieich</Card.Text>
            </Card.Body>
          </Card>
        </Card.Body>
      </Card>
    </div>
  );
}
