import "./App.scss";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Navigation from "./components/Navigation";
import Home from "./components/Home";
import Spende from "./components/Spende";
import Amazon from "./components/Amazon";
import Kontakt from "./components/Kontakt";
import Impressum from "./components/Impressum";
import Projekt from "./components/Projekt";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="appContainer">
      <div className="contentWrap">
        <Router>
          <Navigation />
          <Switch>
            <Route exact path="/">
              <Redirect to="/home" />
            </Route>
            <Route path="/home" component={Home} />
            <Route path="/spende" component={Spende} />
            <Route path="/projekte" component={Projekt} />

            <Route path="/amazon" component={Amazon} />
            <Route path="/kontakt" component={Kontakt} />
            <Route path="/impressum" component={Impressum} />
          </Switch>
        </Router>
      </div>
      <Footer />
    </div>
  );
}

export default App;
